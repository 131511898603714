<template>
  <div class="">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="名称"
                    class="width300"
                    prop="name">
        <el-input v-model="form.name"
                  :disabled="role !== 'ADMIN'"
                  placeholder="请输入网点名称"></el-input>
      </el-form-item>
      <el-form-item label="网点归属"
                    v-permission="['ADMIN']">
        <el-row>
          <el-col :span="16">
            <Area v-model="address"
                  :level="3"
                  @change="areaChange" />
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="网点类型">
        <el-checkbox-group v-model="form.type_ids">
          <el-checkbox :label="1">金融</el-checkbox>
          <el-checkbox :label="2">邮务</el-checkbox>
          <el-checkbox :label="3">寄递事业部</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="网点全称"
                    class="width300"
                    prop="full_name">
        <el-input v-model="form.full_name"
                  placeholder="显示给用户"></el-input>
      </el-form-item>
      <el-form-item label="经度"
                    class="width300"
                    prop="longitude">
        <el-input v-model="form.longitude"
                  placeholder="例如:116.41211"></el-input>
      </el-form-item>
      <el-form-item label="纬度"
                    class="width300"
                    prop="latitude">
        <el-input v-model="form.latitude"
                  placeholder="例如:116.41211"></el-input>
      </el-form-item>
      <el-form-item label="详细地址"
                    class="width300"
                    prop="a_address">
        <el-row type="flex"
                class="mt-15">
          <el-input v-model="form.a_address"
                    class="width300 mr-15"
                    placeholder="请输入详细地址"></el-input>
          <el-button type="primary"
                     @click="$refs.map.initMap({lat:form.latitude,lng:form.longitude})">地图定位</el-button>
        </el-row>
      </el-form-item>
      <el-form-item label="电话"
                    class="width300"
                    prop="phone">
        <el-input v-model="form.phone"
                  placeholder="如：020-88888888"></el-input>
      </el-form-item>
      <el-form-item label="支行长电话"
                    class="width300">
        <el-input v-model="form.branch_tel"
                  placeholder="请输入支行长电话"></el-input>
      </el-form-item>
      <el-form-item label="营业时间"
                    class="width300">
        <el-input v-model="form.open_time"
                  placeholder="如：9:00-18:00"></el-input>
      </el-form-item>
      <el-form-item label="形象图"
                    class="width300">
        <ImgUpload v-model="form.voucher_list_banner_img_id"
                   pixel="选填；用于部分功能"
                   :fileList="imgList" />
      </el-form-item>
      <el-form-item label="机构号"
                    class="width300">
        <el-input v-model="form.org_id"
                  placeholder="选填：邮政内部编号"></el-input>
      </el-form-item>

      <el-form-item label="企业微信配置ID"
                    class="width300">
        <el-input v-model="form.plug_id"
                  placeholder="选填：邮政内部ID"></el-input>
      </el-form-item>
      <el-form-item label="广州专区网点机构号"
                    class="width300">
        <el-input v-model="form.gz_postal_institution_no"
                  placeholder="选填：广州专区网点机构号"></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary"
                   @click="onSubmit('form')">{{form.id?'立即保存':'立即创建'}}</el-button>
      </el-form-item>
    </el-form>
    <Map @on-click="choseLocation"
         ref="map" />
  </div>
</template>

<script>
import Map from '@/components/Map'
import ImgUpload from '@/components/ImgUpload'
import Area from '@/components/Area'
import { setNet, getNetDeail } from '@/api/system'
import { getImgId } from '@/utils'
export default {
  name: 'SetNet',
  data () {
    return {
      imgList: [],
      address: {
        province: '', // 网点省
        city: '', // 网点市
        district: '', // 网点区
        net: ''
      },
      form: {
        name: '', // 员工名称
        province: '', // 网点省
        city: '', // 网点市
        district: '', // 网点区
        area_id: '', //
        a_province: '', // 真实省
        a_city: '', // 真实市
        a_district: '', // 真实区
        a_address: '', // 真实详细地址
        longitude: '', // 经度
        latitude: '', // 纬度
        open_time: '', // 营业时间
        org_id: '', // 机构号
        full_name: '',
        phone: '',
        branch_tel: '',
        voucher_list_banner_img_id: '',
        plug_id: '',
        type_ids: [],
        gz_postal_institution_no: ''// 广州专区网点机号
      },
      rules: {
        name: [{
          required: true,
          message: '请输入网点名称',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '请输入营业电话',
          trigger: 'change'
        }],
        a_address: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'change'
        }],
        full_name: [{
          required: true,
          message: '请输入网点全称',
          trigger: 'change'
        }],
        longitude: [{
          required: true,
          message: '请输入经度',
          trigger: 'change'
        }],
        latitude: [{
          required: true,
          message: '请输入纬度',
          trigger: 'change'
        }]
      },
      dialogVisible: false
    }
  },
  components: {
    Area,
    Map,
    ImgUpload
  },
  computed: {
    role () {
      return this.$store.state.user.role
    }
  },
  methods: {
    // 地图组件回调
    choseLocation (res) {
      const addressComponents = res.addressComponents
      this.form.a_address = res.address
      this.form.longitude = res.location.lng
      this.form.latitude = res.location.lat
      this.form.a_province = addressComponents.province
      this.form.a_city = addressComponents.city
      this.form.a_district = addressComponents.district
    },
    areaChange (res) {
      this.form.province = res.province
      this.form.city = res.city
      this.form.district = res.district
    },
    async getNetDeail (id) {
      const res = await getNetDeail({
        area_id: id
      })
      if (res.meta.code === 0) {
        const area = res.data.area

        area.area_id = id
        area.name = area.a_name
        area.voucher_list_banner_img_id = getImgId(area.voucher_list_banner_img_data)
        area.voucher_list_banner_img_data && this.imgList.push(area.voucher_list_banner_img_data)
        this.address = {
          province: area.province, // 网点省
          city: area.city, // 网点市
          district: area.district, // 网点区
          net: ''
        }
        if (area.type_ids === null || area.type_ids === undefined) {
          area.type_ids = []
        }
        this.form = area
      }
    },
    onSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setNet(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.$router.push('nets')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          return false
        }
      })
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.getNetDeail(this.$route.query.id)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
